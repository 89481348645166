import { FunctionComponent } from 'react';

import { ReactComponent as AccountMultiIcon } from './account-multi.svg';
import { ReactComponent as AccountIcon } from './account.svg';
import { ReactComponent as AddIcon } from './add.svg';
import { ReactComponent as AddChart } from './addChart.svg';
import { ReactComponent as AdministationIcon } from './administation.svg';
import { ReactComponent as AnalyticsIcon } from './analytics.svg';
import { ReactComponent as ArrowLeftIcon } from './arrow-left.svg';
import { ReactComponent as BackIcon } from './back-arrow.svg';
import { ReactComponent as BadgeIcon } from './badge.svg';
import { ReactComponent as BankIcon } from './bank.svg';
import { ReactComponent as BlockList } from './block-list.svg';
import { ReactComponent as BlockIcon } from './block.svg';
import { ReactComponent as BookClosedIcon } from './book-closed.svg';
import { ReactComponent as BookIcon } from './book.svg';
import { ReactComponent as BookMarkIcon } from './bookmark.svg';
import { ReactComponent as BrowseIcon } from './browse.svg';
import { ReactComponent as CaseIcon } from './case.svg';
import { ReactComponent as CheckboxMultiIcon } from './checkboxMulti.svg';
import { ReactComponent as CheckboxMultiEmptyIcon } from './checkboxMultiEmpty.svg';
import { ReactComponent as CheckedFolderIcon } from './checkedFolder.svg';
import { ReactComponent as ChevronIcon } from './chevron.svg';
import { ReactComponent as CloseIcon } from './close.svg';
import { ReactComponent as CollapseIcon } from './collapse.svg';
import { ReactComponent as ConfigIcon } from './config.svg';
import { ReactComponent as CopyIdIcon } from './copy-id.svg';
import { ReactComponent as CopyIcon } from './copy.svg';
import { ReactComponent as CreditingDebitingIcon } from './crediting_debiting.svg';
import { ReactComponent as DashboardIcon } from './dashboard.svg';
import { ReactComponent as DeleteIcon } from './delete.svg';
import { ReactComponent as DevMode } from './dev-mode.svg';
import { ReactComponent as DocumentsIcon } from './documents.svg';
import { ReactComponent as DoneIcon } from './done.svg';
import { ReactComponent as DotsIcon } from './dots.svg';
import { ReactComponent as DownloadIcon } from './download.svg';
import { ReactComponent as EditIcon } from './edit.svg';
import { ReactComponent as ExitIcon } from './exit.svg';
import { ReactComponent as ExportIcon } from './export.svg';
import { ReactComponent as IconEyeClose } from './eye-close.svg';
import { ReactComponent as IconEyeOpen } from './eye-open.svg';
import { ReactComponent as FavProjectIcon } from './fav-projects.svg';
import { ReactComponent as FileCodeIcon } from './file-code.svg';
import { ReactComponent as FileIcon } from './file-empty.svg';
import { ReactComponent as FileImportIcon } from './file-import.svg';
import { ReactComponent as FileNoNameIcon } from './file-noname.svg';
import { ReactComponent as FindIcon } from './find.svg';
import { ReactComponent as FolderManagedIcon } from './folder-managed.svg';
import { ReactComponent as FolderIcon } from './folder.svg';
import { ReactComponent as FullScreenCloseIcon } from './fullscreen-close.svg';
import { ReactComponent as FullScreenOpenIcon } from './fullscreen-open.svg';
import { ReactComponent as GridViewIcon } from './grid-view.svg';
import { ReactComponent as HarmonicIcon } from './harmonic.svg';
import { ReactComponent as HelpIcon } from './help.svg';
import { ReactComponent as HistoryIcon } from './history.svg';
import { ReactComponent as HomeIcon } from './home.svg';
import { ReactComponent as ImportIcon } from './import.svg';
import { ReactComponent as InfoIcon } from './info.svg';
import { ReactComponent as JournalIcon } from './journal.svg';
import { ReactComponent as KeyIcon } from './key.svg';
import { ReactComponent as LaptopIcon } from './laptop.svg';
import { ReactComponent as LegalDataIcon } from './legal-data.svg';
import { ReactComponent as LicenseIcon } from './license.svg';
import { ReactComponent as ListViewIcon } from './list-view.svg';
import { ReactComponent as ListIcon } from './list.svg';
import { ReactComponent as MemoryIcon } from './memory.svg';
import { ReactComponent as MinusIcon } from './minus.svg';
import { ReactComponent as ModulesIcon } from './modules.svg';
import { ReactComponent as MultiFolderIcon } from './multiFolder.svg';
import { ReactComponent as NewFolderIcon } from './new-folder.svg';
import { ReactComponent as NewProjectsIcon } from './new-project.svg';
import { ReactComponent as NewWindow } from './new-window.svg';
import { ReactComponent as NewsletterIcon } from './newsletter.svg';
import { ReactComponent as NoteIcon } from './note.svg';
import { ReactComponent as OrganizationIcon } from './organization.svg';
import { ReactComponent as PasswordIcon } from './password.svg';
import { ReactComponent as PauseIcon } from './pause.svg';
import { ReactComponent as PermissionIcon } from './permissions.svg';
import { ReactComponent as PersonIcon } from './person.svg';
import { ReactComponent as PhoneIcon } from './phone.svg';
import { ReactComponent as PlayIcon } from './play.svg';
import { ReactComponent as PlusIcon } from './plus.svg';
import { ReactComponent as PresentationIcon } from './presentation.svg';
import { ReactComponent as PreviewPanelIcon } from './previewPanel.svg';
import { ReactComponent as ProjectFileIcon } from './project-file.svg';
import { ReactComponent as ProjectList } from './project-list.svg';
import { ReactComponent as ProjectsIcon } from './projects.svg';
import { ReactComponent as RedoIcon } from './redo.svg';
import { ReactComponent as RefundIcon } from './refund.svg';
import { ReactComponent as ReloadIcon } from './reload.svg';
import { ReactComponent as RemoveFolderIcon } from './remove-folder.svg';
import { ReactComponent as RightMouseIcon } from './right-mouse.svg';
import { ReactComponent as RotateRightIcon } from './rotate-right.svg';
import { ReactComponent as SaveEditIcon } from './save-edit.svg';
import { ReactComponent as SaveIcon } from './save.svg';
import { ReactComponent as SchoolIcon } from './school.svg';
import { ReactComponent as ScreenShotIcon } from './screenshot.svg';
import { ReactComponent as SettingsIcon } from './settings.svg';
import { ReactComponent as ShareDemoOffIcon } from './share-demo-off.svg';
import { ReactComponent as ShareDemoIcon } from './share-demo.svg';
import { ReactComponent as SoftStopIcon } from './soft-stop.svg';
import { ReactComponent as SortDateAcsIcon } from './sort-date-asc.svg';
import { ReactComponent as SortDateDescIcon } from './sort-date-desc.svg';
import { ReactComponent as SortASCIcon } from './sortASC.svg';
import { ReactComponent as SortDefaultIcon } from './sortDEFAULT.svg';
import { ReactComponent as SortDESCIcon } from './sortDESC.svg';
import { ReactComponent as StarFullIcon } from './star-full.svg';
import { ReactComponent as StarMinusIcon } from './star-minus.svg';
import { ReactComponent as StarPlusIcon } from './star-plus.svg';
import { ReactComponent as StarIcon } from './star.svg';
import { ReactComponent as StopIcon } from './stop.svg';
import { ReactComponent as SubmodelIcon } from './submodel.svg';
import { ReactComponent as SupportIcon } from './support_agent.svg';
import { ReactComponent as TariffIcon } from './tariff.svg';
import { ReactComponent as TreeIcon } from './tree.svg';
import { ReactComponent as TriangleIcon } from './triangle.svg';
import { ReactComponent as UndoIcon } from './undo.svg';
import { ReactComponent as UpFolderIcon } from './up-folder.svg';
import { ReactComponent as VariableIcon } from './variable.svg';
import { ReactComponent as VisualizationIcon } from './visualization.svg';
import { ReactComponent as VoltageIcon } from './voltage.svg';

interface IIcons {
    [key: string]: FunctionComponent;
}

export const Icons: IIcons = {
    close: () => <CloseIcon />,
    help: () => <HelpIcon />,
    account: () => <AccountIcon />,
    accountMulti: () => <AccountMultiIcon />,
    exit: () => <ExitIcon />,
    chevron: () => <ChevronIcon />,
    tree: () => <TreeIcon />,
    settings: () => <SettingsIcon />,
    config: () => <ConfigIcon />,
    modules: () => <ModulesIcon />,
    find: () => <FindIcon />,
    list: () => <ListIcon />,
    edit: () => <EditIcon />,
    home: () => <HomeIcon />,
    delete: () => <DeleteIcon />,
    phone: () => <PhoneIcon />,
    save: () => <SaveIcon />,
    play: () => <PlayIcon />,
    pause: () => <PauseIcon />,
    stop: () => <StopIcon />,
    softStop: () => <SoftStopIcon />,
    arrowLeft: () => <ArrowLeftIcon />,
    password: () => <PasswordIcon />,
    organization: () => <OrganizationIcon />,
    tariff: () => <TariffIcon />,
    legalData: () => <LegalDataIcon />,
    support: () => <SupportIcon />,
    visualization: () => <VisualizationIcon />,
    journal: () => <JournalIcon />,
    collapse: () => <CollapseIcon />,
    undo: () => <UndoIcon />,
    redo: () => <RedoIcon />,
    add: () => <AddIcon />,
    dots: () => <DotsIcon />,
    book: () => <BookIcon />,
    empty: () => <FileIcon />,
    eyeOpen: () => <IconEyeOpen />,
    eyeClose: () => <IconEyeClose />,
    presentation: () => <PresentationIcon />,
    rotateRight: () => <RotateRightIcon />,
    badge: () => <BadgeIcon />,
    person: () => <PersonIcon />,
    bank: () => <BankIcon />,
    case: () => <CaseIcon />,
    school: () => <SchoolIcon />,
    done: () => <DoneIcon />,
    addChart: () => <AddChart />,
    triangle: () => <TriangleIcon />,
    export: () => <ExportIcon />,
    import: () => <ImportIcon />,
    saveEdit: () => <SaveEditIcon />,
    copy: () => <CopyIcon />,
    copyId: () => <CopyIdIcon />,
    back: () => <BackIcon />,
    plus: () => <PlusIcon />,
    minus: () => <MinusIcon />,
    memory: () => <MemoryIcon />,
    fileCode: () => <FileCodeIcon />,
    fileNoName: () => <FileNoNameIcon />,
    fullScreenOpen: () => <FullScreenOpenIcon />,
    fullScreenClose: () => <FullScreenCloseIcon />,
    download: () => <DownloadIcon />,
    newWindow: () => <NewWindow />,
    license: () => <LicenseIcon />,
    devMode: () => <DevMode />,
    blockList: () => <BlockList />,
    projectList: () => <ProjectList />,
    submodel: () => <SubmodelIcon />,
    info: () => <InfoIcon />,
    variable: () => <VariableIcon />,
    browse: () => <BrowseIcon />,
    laptop: () => <LaptopIcon />,
    note: () => <NoteIcon />,
    bookClosed: () => <BookClosedIcon />,
    voltage: () => <VoltageIcon />,
    harmonic: () => <HarmonicIcon />,
    key: () => <KeyIcon />,
    history: () => <HistoryIcon />,
    bookmark: () => <BookMarkIcon />,
    projects: () => <ProjectsIcon />,
    newProject: () => <NewProjectsIcon />,
    favProject: () => <FavProjectIcon />,
    star: () => <StarIcon />,
    starFull: () => <StarFullIcon />,
    starPlus: () => <StarPlusIcon />,
    starMinus: () => <StarMinusIcon />,
    gridView: () => <GridViewIcon />,
    listView: () => <ListViewIcon />,
    projectFile: () => <ProjectFileIcon />,
    analytics: () => <AnalyticsIcon />,
    shareDemo: () => <ShareDemoIcon />,
    shareDemoOff: () => <ShareDemoOffIcon />,
    folder: () => <FolderIcon />,
    newFolder: () => <NewFolderIcon />,
    removeFolder: () => <RemoveFolderIcon />,
    upFolder: () => <UpFolderIcon />,
    sortASC: () => <SortASCIcon />,
    sortDESC: () => <SortDESCIcon />,
    sortDefault: () => <SortDefaultIcon />,
    previewPanel: () => <PreviewPanelIcon />,
    multiFolder: () => <MultiFolderIcon />,
    checkedFolder: () => <CheckedFolderIcon />,
    fileImport: () => <FileImportIcon />,
    checkboxMulti: () => <CheckboxMultiIcon />,
    checkboxMultiEmpty: () => <CheckboxMultiEmptyIcon />,
    rightMouse: () => <RightMouseIcon />,
    sortDateAsc: () => <SortDateAcsIcon />,
    sortDateDesc: () => <SortDateDescIcon />,
    screenShot: () => <ScreenShotIcon />,
    dashboard: () => <DashboardIcon />,
    creditingDebiting: () => <CreditingDebitingIcon />,
    documents: () => <DocumentsIcon />,
    administration: () => <AdministationIcon />,
    refund: () => <RefundIcon />,
    newsletter: () => <NewsletterIcon />,
    reload: () => <ReloadIcon />,
    permission: () => <PermissionIcon />,
    folderManaged: () => <FolderManagedIcon />,
    block: () => <BlockIcon />,
};
