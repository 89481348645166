import { FC, useEffect, useState } from 'react';

import { useAppSelector } from '@repeat/hooks';
import { workspaceSelectors } from '@repeat/store';
import { SButton, SListItem, SMenuDropdown, SProjectName, SWorkspaceProjectName } from './SWorkspaceProjectMenu';

interface IBreadCrumbsProps {
    handleProjectNameClick: () => void;
    handleGroupNameClick: (id: string) => void;
    projectName: string;
    path: { id: string | null; name: string }[];
}

function getTextWidth(text: string, font?: string) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
        return 0;
    }
    context.font = font || '14px Rosatom';
    const metrics = context.measureText(text);
    return metrics.width;
}

function getTextArrayWidth(text: string[]) {
    return getTextWidth(text.join(' / ')) + 8;
}

export const MenuBreadCrumbs: FC<IBreadCrumbsProps> = ({
    handleProjectNameClick,
    handleGroupNameClick,
    projectName,
    path,
}) => {
    const isUserBlockEditorMode = useAppSelector(workspaceSelectors.isUserBlockEditor);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const namesAll = [projectName, ...path.map((item) => item.name)].reverse();
    const namesVisible = [namesAll[0]];
    const namesHidden: string[] = [];
    namesAll.slice(1).forEach((name) => {
        if (Number(getTextArrayWidth([...namesVisible, name])) <= 300) {
            namesVisible.push(name);
        } else {
            namesHidden.push(name);
        }
    });
    const pathVisible = path.slice(-namesVisible.length);
    const pathHidden = path.slice(0, path.length - namesVisible.length);

    useEffect(() => {
        if (namesHidden.length > 0) {
            setIsButtonVisible(true);
        }
    }, [namesHidden]);

    return (
        <>
            <SButton
                variant={'outlined'}
                onClick={() => {
                    setIsDropdownVisible(!isDropdownVisible);
                }}
                isVisible={isButtonVisible}
            >
                ...
            </SButton>
            <SWorkspaceProjectName isGroup data-tour='workspace-project-name'>
                {namesHidden.length === 0 && !isUserBlockEditorMode && (
                    <>
                        <SProjectName onClick={handleProjectNameClick}>{projectName}</SProjectName> &nbsp;/&nbsp;
                    </>
                )}

                {pathVisible.map((el, index) =>
                    index === pathVisible.length - 1 ? (
                        <span key={el.id}>{el.name}</span>
                    ) : (
                        <SProjectName
                            key={el.id}
                            onClick={() => {
                                el.id && handleGroupNameClick(el.id);
                            }}
                        >
                            {el.name}
                            &nbsp;/&nbsp;
                        </SProjectName>
                    )
                )}
            </SWorkspaceProjectName>
            {namesHidden.length !== 0 && (
                <SMenuDropdown isVisible={isDropdownVisible}>
                    {pathHidden.reverse().map((item) => (
                        <SListItem key={item.id}>
                            <SProjectName
                                onClick={() => {
                                    item.id && handleGroupNameClick(item.id);
                                    setIsDropdownVisible(false);
                                }}
                            >
                                {item.name}
                            </SProjectName>
                        </SListItem>
                    ))}
                    <SListItem>
                        <SProjectName onClick={handleProjectNameClick}>{projectName}</SProjectName>
                    </SListItem>
                </SMenuDropdown>
            )}
        </>
    );
};
