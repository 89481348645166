import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import {
    CANVAS,
    LIBRARIES_DEFAULT_LIST,
    calculateGroupNodeIndex,
    calculatePortBlockCoordinates,
    calculatePortsLength,
    countMaxOfInputsAndOutputs,
    extractLetters,
    extractNumbers,
    findOuterGroupsPropertiesToUpdate,
    findParentGroupBlock,
    findPortDetails,
    getHandleName,
    getParentParameterName,
    getPortElementLibraryName,
} from '@repeat/constants';
import {
    ILibraryItem,
    IWorkspaceState,
    PortConnectionTypes,
    PortPositions,
    PortTypes,
    Statuses,
    TPortConnectionType,
    TSchemaConnection,
    TSchemaElementWithParams,
    TSchemaGroup,
    TSchemaHandle,
    TSchemaNode,
    TSubmodelProxyItem,
    WireTypes,
    WorkspaceModes,
} from '@repeat/models';
import { makeSchemaNode } from '@repeat/services';
import { workspaceActions } from '@repeat/store';
import { increaseUserBlocksCount } from '../slices/workspace/schema/helper';

const updateStateWithNewNode = (workspaceState: IWorkspaceState, node: TSchemaNode) => {
    const { mode, elementId } = workspaceState.meta;
    let userBlocksCount = workspaceState.schema.userBlocksCount || {};
    const blockId = node.data.blockId;
    if (node.data.type === 'userBlock' && blockId) {
        userBlocksCount = increaseUserBlocksCount({ ...userBlocksCount }, [blockId]);
    }
    if (mode === WorkspaceModes.MAIN) {
        return {
            ...workspaceState,
            schema: {
                ...workspaceState.schema,
                schemaItems: {
                    ...workspaceState.schema.schemaItems,
                    elements: [...workspaceState.schema.schemaItems.elements, node],
                },
                userBlocksCount,
                elementsWithParams: [
                    ...workspaceState.schema.elementsWithParams,
                    {
                        id: node.data.id,
                        blockId: node.data.blockId || null,
                        name: node.data.name,
                        index: node.data.index,
                        elemParams: [...node.data.elemParams],
                    } as TSchemaElementWithParams,
                ],
            },
        };
    }
    const groups: TSchemaGroup[] = workspaceState.schema.schemaItems.groups
        ? workspaceState.schema.schemaItems.groups.map((group: TSchemaGroup) => {
              if (group.id.toString() === elementId) {
                  return {
                      ...group,
                      elements: [...group.elements, node],
                  };
              }
              return group;
          })
        : [];
    return {
        ...workspaceState,
        schema: {
            ...workspaceState.schema,
            schemaItems: {
                ...workspaceState.schema.schemaItems,
                groups,
            },
            userBlocksCount,
        },
    };
};

const setProxyMapParameter = (node: TSchemaNode): TSubmodelProxyItem => ({
    internalBlockId: node.data.id,
    internalName: '',
    name: node.data.elemProps.find((prop) => prop.name === 'parentParameter')?.value.toString() || '',
});

const setParameterByPort = (
    port: TSchemaHandle,
    inputPortLibraryItem: ILibraryItem,
    outputPortLibraryItem: ILibraryItem
) => {
    const parameter = {
        description: '',
        modelName: '',
        name: port.name,
        unit: '',
        value: '',
    };
    const portNumber = extractNumbers(port.name);
    if (port.type === PortTypes.INPUT) {
        return { ...parameter, description: `${inputPortLibraryItem.name}_${portNumber}` };
    }

    return { ...parameter, description: `${outputPortLibraryItem.name}_${portNumber}` };
};

const isPortConnected = (wires: TSchemaConnection[], id: string, name: string) => {
    const handlesIds = wires.map((wire) => [wire.sourceHandle, wire.targetHandle]).flat();

    const handleIdWithCurrentElementId = handlesIds.filter((handleId) => handleId.includes(id));
    if (handleIdWithCurrentElementId && handleIdWithCurrentElementId.filter((id) => id.includes(name)).length !== 0) {
        return true;
    }
    return false;
};

export const addGroup =
    (payload: { elementId: string; node: TSchemaNode }) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        const { elementId, node } = payload;

        const state = getState().workspace;

        const stateUpdatedWithNewNode = updateStateWithNewNode(state, node);
        const {
            schema: {
                schemaItems: { groups, elements: elementsState },
                itemsForSubmodel,
            },
            libraryPortTypes: { items: portsTypes },
            libraryItems: { items: libraryItems },
            meta: { elementId: metaElementId },
        } = stateUpdatedWithNewNode;

        const groupsState = groups || [];
        const { elements: elementsTmp, wires } = itemsForSubmodel;
        const inputPortLibraryItem = libraryItems.find((item) => item.type === 'InPort');
        const outputPortLibraryItem = libraryItems.find((item) => item.type === 'OutPort');
        const groupBlocksInItemsForSubmodelIds = elementsTmp
            .filter((el) => el.data.type === 'group')
            .map((el) => el.id);

        if (!elementId || !inputPortLibraryItem || !outputPortLibraryItem) {
            return;
        }

        const elementsIds = elementsTmp.map((el) => el.id);

        const updatePortElementWithProps = (element: TSchemaNode, index: number, type: string) => {
            const parentParameterValue = `${type}_${index + 1}`;

            const elemProps = element.data.elemProps.map((prop) => {
                if (prop.name === 'parentParameter') {
                    return { ...prop, value: parentParameterValue };
                }
                return prop;
            });

            return { ...element, data: { ...element.data, elemProps } };
        };

        const elementsInPorts = elementsTmp
            .filter((el) => el.data.type === 'InPort')
            .map((el, i) => updatePortElementWithProps(el, i, 'in'));
        const elementsOutPorts = elementsTmp
            .filter((el) => el.data.type === 'OutPort')
            .map((el, i) => updatePortElementWithProps(el, i, 'out'));
        const elements = [
            ...elementsTmp.filter((el) => el.data.type !== 'InPort' && el.data.type !== 'OutPort'),
            ...elementsInPorts,
            ...elementsOutPorts,
        ];

        const elementsInPortsNames = elementsInPorts.map((el) => getParentParameterName(el));
        const elementsOutPortsNames = elementsOutPorts.map((el) => getParentParameterName(el));

        const elementsPortsNames = [...elementsInPortsNames, ...elementsOutPortsNames];

        const updateSchemaItems = (schemaElements: TSchemaNode[], schemaWires: TSchemaConnection[]) => {
            const wiresNew = schemaWires.filter(
                (wire) => elementsIds.includes(wire.source) && elementsIds.includes(wire.target)
            ); // в группу попадают только те соединения, у которых начальный и конечный блоки есть в группе. Необязательно выделенные

            const wiresToUpdate = schemaWires.filter((x) => !wiresNew.some((y) => x.id === y.id)); // которые нужно перестроить к блоку Группа

            const internalPortsByElementId: { [key: string]: string[] } = {}; // занятые порты по элементам внутри группы
            wiresNew.forEach((wire) => {
                if (wire.source in internalPortsByElementId) {
                    internalPortsByElementId[wire.source].push(getHandleName(wire.sourceHandle));
                } else {
                    internalPortsByElementId[wire.source] = [getHandleName(wire.sourceHandle)];
                }

                if (wire.target in internalPortsByElementId) {
                    internalPortsByElementId[wire.target].push(getHandleName(wire.targetHandle));
                } else {
                    internalPortsByElementId[wire.target] = [getHandleName(wire.targetHandle)];
                }
            });

            const externalPorts: string[] = []; // свободные порты элементов в группе, для которых надо добавить блоки-порты

            const externalPortsByElementId: { [key: string]: string } = {};
            const newPortsNamesByOldPorts: { [key: string]: string } = {}; // имена блоков-портов по айди портов, как они были до группировки
            const externalPortsConnectionTypesByPortId: { [key: string]: string } = {};

            elements.forEach((el) => {
                el.data.availablePorts.forEach((port) => {
                    if (
                        !(el.id in internalPortsByElementId) ||
                        (el.id in internalPortsByElementId && !internalPortsByElementId[el.id].includes(port.name))
                    ) {
                        externalPorts.push(port.name);
                        const portId = `${el.id}-${extractNumbers(port.name)}-${port.name}`;

                        externalPortsByElementId[portId] = el.id;
                        externalPortsConnectionTypesByPortId[portId] = port.typeConnection;
                    }
                });
            });

            const externalPortsInputNames = externalPorts.filter((p) => p.includes('in'));
            const externalPortsOutputNames = externalPorts.filter((p) => p.includes('out'));

            const externalInputPorts = externalPortsInputNames.map((name, i) => {
                const elementId = Date.now() + i;

                const index = ''; // ???

                const { x, y } = calculatePortBlockCoordinates(itemsForSubmodel.elements, PortTypes.INPUT);

                const newNode = makeSchemaNode(
                    inputPortLibraryItem,
                    {
                        id: elementId,
                        index,
                        position: { x, y: y + 30 * i },
                        nodeType: 'element',
                    },
                    portsTypes
                );
                const {
                    data: { view },
                } = newNode;

                const updatedNewNode = {
                    ...newNode,
                    width: view?.minWidth || CANVAS.ELEMENT_MIN_WIDTH,
                    height: view?.minHeight || CANVAS.ELEMENT_MIN_HEIGHT,
                    rotation: 0,
                };
                const elemProps = updatedNewNode.data.elemProps.map((prop) => {
                    const index = elementsInPortsNames.length + i + 1;
                    const parentParameterValue = `in_${index}`;

                    if (prop.name === 'parentParameter') {
                        return { ...prop, value: parentParameterValue };
                    }
                    const description = getPortElementLibraryName(libraryItems, 'in');
                    if (prop.name === 'userParameterName') {
                        return { ...prop, value: `${description}_${extractNumbers(parentParameterValue)}` };
                    }
                    return prop;
                });
                const availablePorts = updatedNewNode.data.availablePorts.map((port) => ({
                    ...port,
                    isConnected: true,
                }));
                const updatedNewNodeWithProps = {
                    ...updatedNewNode,
                    data: { ...updatedNewNode.data, elemProps, availablePorts },
                };

                return updatedNewNodeWithProps;
            });

            const externalOutputPorts = externalPortsOutputNames.map((name, i) => {
                const elementId = Date.now() + i + externalInputPorts.length;

                const index = ''; // ???

                const { x, y } = calculatePortBlockCoordinates(itemsForSubmodel.elements, PortTypes.OUTPUT);

                const newNode = makeSchemaNode(
                    outputPortLibraryItem,
                    {
                        id: elementId,
                        index,
                        position: { x, y: y + 30 * i },
                        nodeType: 'element',
                    },
                    portsTypes
                );
                const {
                    data: { view },
                } = newNode;

                const updatedNewNode = {
                    ...newNode,
                    width: view?.minWidth || CANVAS.ELEMENT_MIN_WIDTH,
                    height: view?.minHeight || CANVAS.ELEMENT_MIN_HEIGHT,
                    rotation: 0,
                };
                const elemProps = updatedNewNode.data.elemProps.map((prop) => {
                    const index = elementsOutPortsNames.length + i + 1;
                    const parentParameterValue = `out_${index}`;
                    if (prop.name === 'parentParameter') {
                        return { ...prop, value: parentParameterValue };
                    }
                    const description = getPortElementLibraryName(libraryItems, 'out');
                    if (prop.name === 'userParameterName') {
                        return { ...prop, value: `${description}_${extractNumbers(parentParameterValue)}` };
                    }
                    return prop;
                });
                const availablePorts = updatedNewNode.data.availablePorts.map((port) => ({
                    ...port,
                    isConnected: true,
                }));

                const updatedNewNodeWithProps = {
                    ...updatedNewNode,
                    data: { ...updatedNewNode.data, elemProps, availablePorts },
                };

                return updatedNewNodeWithProps;
            });
            const wiresCreatedForInputPorts: TSchemaConnection[] = [];
            const wiresCreatedForOutputPorts: TSchemaConnection[] = [];

            const externalInputs = [...externalInputPorts, ...elementsInPorts];
            const externalOutputs = [...elementsOutPorts, ...externalOutputPorts];

            const proxyMapParams = [...externalInputs, ...externalOutputs].map((node) => setProxyMapParameter(node));
            const inputHandles = [...externalInputPorts];
            Object.keys(externalPortsByElementId)
                .filter((id) => id.includes('in'))
                .forEach((id, i) => {
                    const data = { id: 0, index: '', type: WireTypes.WIRE, wireParams: [], wireProps: [] };
                    const port = inputHandles[0];

                    wiresCreatedForInputPorts.push({
                        data,
                        id: (Date.now() + Number(port.id)).toString(), // TODO generate unique id without Date.now()
                        source: port.id,
                        sourceHandle: `${port.id}-1-out_1`,
                        target: externalPortsByElementId[id],
                        targetHandle: id,
                        type: 'custom-edge',
                    });
                    const portParentParameter =
                        port.data.elemProps.find((prop) => prop.name === 'parentParameter')?.value.toString() || '';
                    newPortsNamesByOldPorts[id] = portParentParameter;
                    inputHandles.shift();
                });

            const outputHandles = [...externalOutputPorts];
            Object.keys(externalPortsByElementId)
                .filter((id) => id.includes('out'))
                .forEach((id, i) => {
                    const data = { id: 0, index: '', type: WireTypes.WIRE, wireParams: [], wireProps: [] };
                    const port = outputHandles[0];
                    wiresCreatedForOutputPorts.push({
                        data,
                        id: (Date.now() + Number(port.id)).toString(), // TODO generate unique id without Date.now()
                        source: port.id,

                        sourceHandle: `${port.id}-1-in_1`,
                        target: externalPortsByElementId[id],
                        targetHandle: id,
                        type: 'custom-edge',
                    });
                    const portParentParameter =
                        port.data.elemProps.find((prop) => prop.name === 'parentParameter')?.value.toString() || '';
                    newPortsNamesByOldPorts[id] = portParentParameter;
                    outputHandles.shift();
                });

            const updatedWires = wiresToUpdate.map((wire) => {
                if (!elementsIds.includes(wire.source) && elementsIds.includes(wire.target)) {
                    return {
                        ...wire,
                        target: elementId,
                        targetHandle: `${elementId}-${extractNumbers(newPortsNamesByOldPorts[wire.targetHandle])}-${
                            newPortsNamesByOldPorts[wire.targetHandle]
                        }`,
                    };
                }
                if (elementsIds.includes(wire.source) && !elementsIds.includes(wire.target)) {
                    return {
                        ...wire,
                        source: elementId,
                        sourceHandle: `${elementId}-${extractNumbers(newPortsNamesByOldPorts[wire.sourceHandle])}-${
                            newPortsNamesByOldPorts[wire.sourceHandle]
                        }`,
                    };
                }
                return wire;
            });

            const groups: TSchemaGroup[] = groupsState;

            let groupsNew: TSchemaGroup[] = [];

            const elementsNew = schemaElements
                .filter((x) => !elements.some((y) => x.id === y.id))
                .map((el) => {
                    if (el.id === elementId) {
                        const elementsConnected = elements.map((el) => {
                            const availablePorts = el.data.availablePorts.map((port) => ({
                                ...port,
                                isConnected: true,
                            }));
                            return { ...el, data: { ...el.data, availablePorts } };
                        }); // все порты элементов внутри группы всегда подключены
                        const groupsUpd = groups.map((group) => {
                            if (groupBlocksInItemsForSubmodelIds.includes(group.id.toString())) {
                                return { ...group, parentGroupId: elementId };
                            }
                            return group;
                        });
                        const groupElements = [...elementsConnected, ...externalInputPorts, ...externalOutputPorts].map(
                            (el, i) => ({ ...el, data: { ...el.data, index: (i + 1).toString() } })
                        );
                        groupsNew = [
                            ...groupsUpd,
                            {
                                id: Number(elementId),
                                elements: groupElements,
                                wires: [...wiresNew, ...wiresCreatedForInputPorts, ...wiresCreatedForOutputPorts],
                                parentGroupId: metaElementId,
                                name: '',
                            },
                        ];

                        const findPortTypeConnection = (name: string): TPortConnectionType => {
                            const isInPort = name.includes('in');
                            const wires = [...wiresNew, ...wiresCreatedForInputPorts, ...wiresCreatedForOutputPorts];
                            const portBlock = groupElements.find((node) => getParentParameterName(node) === name);

                            const defaultType = isInPort
                                ? PortConnectionTypes.DEFAULT_INPUT
                                : PortConnectionTypes.DEFAULT_OUTPUT;
                            if (!portBlock) {
                                return defaultType;
                            }

                            const wireWithPortBlock = wires.find(
                                (wire) => wire.source === portBlock.id || wire.target === portBlock.id
                            );

                            if (!wireWithPortBlock) {
                                return defaultType;
                            }

                            const connectedHandleNameByElementId: { [id: string]: string } = {};
                            if (wireWithPortBlock.source === portBlock.id) {
                                connectedHandleNameByElementId[wireWithPortBlock.target] = getHandleName(
                                    wireWithPortBlock.targetHandle
                                );
                            }
                            if (wireWithPortBlock.target === portBlock.id) {
                                connectedHandleNameByElementId[wireWithPortBlock.source] = getHandleName(
                                    wireWithPortBlock.sourceHandle
                                );
                            }

                            const connectedBlockPortTypeConnection = groupElements
                                .find((el) => el.id === Object.keys(connectedHandleNameByElementId)[0])
                                ?.data.availablePorts.find(
                                    (port) => port.name === Object.values(connectedHandleNameByElementId)[0]
                                )?.typeConnection;

                            return connectedBlockPortTypeConnection || defaultType;
                        };

                        const inputPorts = proxyMapParams
                            .map((p) => p.name)
                            .filter((name) => name.includes('in'))
                            .map((name, i) => {
                                const isConnected = isPortConnected(updatedWires, elementId, name);
                                const typeConnection = findPortTypeConnection(name);

                                const { direction, compatibleTypes } = findPortDetails(
                                    portsTypes,
                                    typeConnection,
                                    name
                                );
                                return {
                                    name,
                                    position: PortPositions.LEFT,
                                    libraries: LIBRARIES_DEFAULT_LIST,
                                    type: PortTypes.INPUT,
                                    typeConnection,
                                    direction,
                                    compatibleTypes,
                                    isConnected,
                                };
                            });

                        const outputPorts = proxyMapParams
                            .map((p) => p.name)
                            .filter((name) => name.includes('out'))
                            .map((name, i) => {
                                const isConnected = isPortConnected(updatedWires, elementId, name);
                                const typeConnection = findPortTypeConnection(name);

                                const { direction, compatibleTypes } = findPortDetails(
                                    portsTypes,
                                    typeConnection,
                                    name
                                );

                                return {
                                    name,
                                    position: PortPositions.RIGHT,
                                    libraries: LIBRARIES_DEFAULT_LIST,
                                    type: PortTypes.OUTPUT,
                                    typeConnection,
                                    direction,
                                    compatibleTypes,
                                    isConnected,
                                };
                            });
                        const ports = [...inputPorts, ...outputPorts];

                        const elemParams = ports.map((p) =>
                            setParameterByPort(p, inputPortLibraryItem, outputPortLibraryItem)
                        );
                        const maxPortsLength = calculatePortsLength(
                            countMaxOfInputsAndOutputs(ports),
                            CANVAS.PORT_MARGIN
                        );
                        const newHeight = Math.max(maxPortsLength, CANVAS.ELEMENT_MIN_HEIGHT);

                        return {
                            ...el,
                            height: newHeight,
                            data: {
                                ...el.data,
                                availablePorts: ports,
                                elemParams,
                                proxyMap: { params: proxyMapParams, props: [] },
                                index: calculateGroupNodeIndex(
                                    schemaElements.filter((x) => !elements.some((y) => x.id === y.id))
                                ),
                            },
                        };
                    }
                    return el;
                });
            const elementsWithParamsNew = stateUpdatedWithNewNode.schema.elementsWithParams
                .filter((x) => !elements.some((y) => x.id.toString() === y.id))
                .map((el) => {
                    if (el.id.toString() === elementId) {
                        const inputPorts = [...elementsInPortsNames, ...externalPortsInputNames].map((name, i) => {
                            const typeConnection = PortConnectionTypes.DEFAULT_INPUT;
                            const { direction, compatibleTypes } = findPortDetails(portsTypes, typeConnection, name);
                            return {
                                name: `${extractLetters(name)}_${i + 1}`,
                                position: PortPositions.LEFT,
                                libraries: LIBRARIES_DEFAULT_LIST,
                                type: PortTypes.INPUT,
                                isConnected: false, // TODO !!! не всегда
                                typeConnection,
                                direction,
                                compatibleTypes,
                            };
                        });
                        const outputPorts = [...elementsOutPortsNames, ...externalPortsOutputNames].map((name, i) => {
                            const typeConnection = PortConnectionTypes.DEFAULT_OUTPUT;
                            const { direction, compatibleTypes } = findPortDetails(portsTypes, typeConnection, name);
                            return {
                                name: `${extractLetters(name)}_${i + 1}`,
                                position: PortPositions.RIGHT,
                                libraries: LIBRARIES_DEFAULT_LIST,
                                type: PortTypes.OUTPUT,
                                isConnected: false, // TODO !!! не всегда
                                typeConnection,
                                direction,
                                compatibleTypes,
                            };
                        });
                        const ports = [...inputPorts, ...outputPorts];

                        const elemParams = ports.map((p) =>
                            setParameterByPort(p, inputPortLibraryItem, outputPortLibraryItem)
                        );

                        return {
                            ...el,
                            elemParams,
                        };
                    }
                    return el;
                });
            return { elementsNew, updatedWires, groupsNew, elementsWithParamsNew };
        };

        if (metaElementId === null) {
            // верхний уровень схемы
            const {
                schemaItems: { elements, wires },
                externalInfo,
                proxyMap,
            } = stateUpdatedWithNewNode.schema;
            const { elementsNew, updatedWires, groupsNew, elementsWithParamsNew } = updateSchemaItems(elements, wires);
            const externalInfoUpdated = externalInfo
                ? {
                      ...externalInfo,
                      parameters:
                          externalInfo?.parameters?.filter((param) => !elementsPortsNames.includes(param.name)) || [],
                  }
                : { parameters: [], properties: [] };

            const proxyMapUpdated = proxyMap
                ? { ...proxyMap, params: proxyMap.params.filter((param) => !elementsPortsNames.includes(param.name)) }
                : { params: [], props: [] };

            dispatch(
                workspaceActions.setBlocksGroup({
                    state: {
                        ...state,
                        schema: {
                            ...stateUpdatedWithNewNode.schema,
                            schemaItems: {
                                ...stateUpdatedWithNewNode.schema.schemaItems,
                                elements: elementsNew,
                                wires: updatedWires,
                                groups: groupsNew,
                            },
                            externalInfo: externalInfoUpdated,
                            proxyMap: proxyMapUpdated,
                            elementsWithParams: elementsWithParamsNew,
                            getSubmodelsSchemaItems: {
                                ...stateUpdatedWithNewNode.schema.getSubmodelsSchemaItems,
                                status: Statuses.SUCCEEDED,
                            },
                            itemsForSubmodel: { elements: [], wires: [] },
                        },
                    },
                    elementId: payload.elementId,
                })
            );
            return;
        }
        const currentGroup = groupsState.find((group) => group.id.toString() === metaElementId);
        const elementsToUpdate = currentGroup ? currentGroup.elements : [];
        const wiresToUpdate = currentGroup ? currentGroup.wires : [];
        const { elementsNew, updatedWires, groupsNew, elementsWithParamsNew } = updateSchemaItems(
            elementsToUpdate,
            wiresToUpdate
        );

        const groupsResult = groupsNew.map((group) => {
            if (group.id.toString() === metaElementId) {
                return { ...group, elements: elementsNew, wires: updatedWires };
            }
            return group;
        });
        const { propsByElementId, parentIdByGroupId } = findOuterGroupsPropertiesToUpdate(
            itemsForSubmodel.elements,
            metaElementId,
            groupsResult,
            elementsState,
            elementId
        );
        const allPropsToUpdate: { [oldName: string]: string }[] = [];
        Object.values(propsByElementId).forEach((pr) => {
            allPropsToUpdate.push(...pr);
        });

        // ВЫНЕСТИ В ФУНКЦИЮ findOuterGroupsPropertiesToUpdate, чтобы она возвращала уже objForUpd:
        const objForUpd: { [parentId: string]: { elId: string; props: { [old: string]: string }[] } } = {};
        Object.keys(parentIdByGroupId).forEach((groupId) => {
            if (propsByElementId[groupId]) {
                if (parentIdByGroupId[groupId] !== null) {
                    objForUpd[parentIdByGroupId[groupId] || 'MAIN'] = {
                        elId: groupId,
                        props: propsByElementId[groupId],
                    };
                } else {
                    objForUpd['MAIN'] = { elId: groupId, props: propsByElementId[groupId] };
                }
            }
        });
        const currentElementParentGroup = findParentGroupBlock(groupsResult, elementsState, metaElementId);
        const currentProps = currentElementParentGroup ? currentElementParentGroup.data.elemProps : [];

        const submodelElementsIds = itemsForSubmodel.elements.map((el) => el.id);

        const groupsResRes = groupsResult.map((group) => {
            const propsByElementToUpdate = objForUpd[group.id.toString()];

            if (group.id.toString() === metaElementId) {
                const elements = group.elements.map((el) => {
                    if (el.id === elementId) {
                        const props = itemsForSubmodel.elements.map((item) => [...item.data.elemProps]).flat();
                        const propsNames = props.map((prop) => prop.name);

                        const possibleNames = propsNames
                            .map((name) => {
                                return submodelElementsIds.map((id) => {
                                    return `${name}-${id}`;
                                });
                            })
                            .flat();

                        const propsRes = currentProps.filter((prop) =>
                            possibleNames.some((str) => prop.name.includes(str))
                        );
                        return {
                            ...el,
                            data: {
                                ...el.data,
                                elemProps: propsRes,
                            },
                        };
                    }
                    return el;
                });
                return { ...group, elements };
            }
            if (!propsByElementToUpdate) {
                return group;
            }
            // TODO вынести в функцию обновление элементов - т к то же самое нужно на верхнем уровне схемы
            const elements = group.elements.map((el) => {
                if (el.id === elementId) {
                    return el;
                }
                if (el.id === propsByElementToUpdate.elId) {
                    const elemProps = el.data.elemProps.map((prop) => {
                        const foundProp = propsByElementToUpdate.props.find((p) => p[prop.name]);
                        if (foundProp) {
                            return { ...prop, name: Object.values(foundProp)[0] };
                        }
                        return prop;
                    });

                    const proxyMapProps = el.data.proxyMap
                        ? el.data.proxyMap.props.map((prop) => {
                              const foundProp = propsByElementToUpdate.props.find((p) => p[prop.name]);
                              const foundInternalName = allPropsToUpdate.find((p) => p[prop.internalName]);
                              const name = foundProp ? Object.values(foundProp)[0] : '';
                              const nameSplitted = name.split('-');
                              const internalBlockId = Number(nameSplitted[nameSplitted.length - 1]);
                              if (extractNumbers(prop.internalName) === 0 && foundProp) {
                                  return {
                                      ...prop,
                                      name,
                                      internalName: Object.keys(foundProp)[0],
                                      internalBlockId,
                                  };
                              }
                              if (foundProp && foundInternalName) {
                                  return {
                                      ...prop,
                                      name,
                                      internalName: Object.values(foundInternalName)[0],
                                      internalBlockId,
                                  };
                              }
                              return prop;
                          })
                        : [];
                    return {
                        ...el,
                        data: {
                            ...el.data,
                            elemProps,
                            proxyMap: el.data.proxyMap
                                ? { ...el.data.proxyMap, props: proxyMapProps }
                                : { params: [], props: proxyMapProps },
                        },
                    };
                }
                return el;
            });
            return { ...group, elements };
        });

        const elementsNewUpd = elementsState.map((el) => {
            const propsByElementToUpdate = objForUpd['MAIN'];
            if (!propsByElementToUpdate) {
                return el;
            }
            if (el.id === propsByElementToUpdate.elId) {
                const elemProps = el.data.elemProps.map((prop) => {
                    const foundProp = propsByElementToUpdate.props.find((p) => p[prop.name]);

                    if (foundProp) {
                        return {
                            ...prop,
                            name: Object.values(foundProp)[0],
                        };
                    }
                    return prop;
                });

                const proxyMapProps = el.data.proxyMap
                    ? el.data.proxyMap.props.map((prop) => {
                          const foundProp = propsByElementToUpdate.props.find((p) => p[prop.name]);
                          const foundInternalName = allPropsToUpdate.find((p) => p[prop.internalName]);
                          const name = foundProp ? Object.values(foundProp)[0] : '';
                          const nameSplitted = name.split('-');
                          const internalBlockId = Number(nameSplitted[nameSplitted.length - 1]);

                          if (extractNumbers(prop.internalName) === 0 && foundProp) {
                              return {
                                  ...prop,
                                  name,
                                  internalName: Object.keys(foundProp)[0],
                                  internalBlockId,
                              };
                          }

                          if (foundProp && foundInternalName) {
                              return {
                                  ...prop,
                                  name,
                                  internalName: Object.values(foundInternalName)[0],
                                  internalBlockId,
                              };
                          }
                          return prop;
                      })
                    : [];
                return {
                    ...el,
                    data: {
                        ...el.data,
                        elemProps,
                        proxyMap: el.data.proxyMap
                            ? { ...el.data.proxyMap, props: proxyMapProps }
                            : { params: [], props: proxyMapProps },
                    },
                };
            }
            return el;
        });

        dispatch(
            workspaceActions.setBlocksGroup({
                state: {
                    ...state,
                    schema: {
                        ...stateUpdatedWithNewNode.schema,
                        schemaItems: {
                            ...stateUpdatedWithNewNode.schema.schemaItems,
                            elements: elementsNewUpd,
                            groups: groupsResRes,
                        },
                        getSubmodelsSchemaItems: {
                            ...stateUpdatedWithNewNode.schema.getSubmodelsSchemaItems,
                            status: Statuses.SUCCEEDED,
                        },
                        itemsForSubmodel: { elements: [], wires: [] },
                    },
                },
                elementId: payload.elementId,
            })
        );
    };
