import { NavigateFunction } from 'react-router-dom';

import {
    ActionReducerMapBuilder,
    AnyAction,
    createAsyncThunk,
    createSlice,
    PayloadAction,
    ThunkDispatch,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
    LicenseActivationStatuses,
    LicenseValidationStatuses,
    TLicenseActivationStatus,
    TLicenseValidationStatus,
} from 'libs/models/src/lib/license';

import { ApplicationActions } from '@repeat/common-slices';
import {
    API_BAD_REQUEST_CODE,
    API_GATEWAY_TIMEOUT_CODE,
    API_NOT_FOUND_CODE,
    API_SERVICE_UNAVAILABLE_CODE,
    getAppLocale,
    getCookie,
    getExpirationDuration,
    getPlatform,
    LICENSE_DISTRIBS,
    LICENSE_WARNING_LEFT_DAYS,
    setLocale,
    setTheme,
} from '@repeat/constants';
import { environment } from '@repeat/environment';
import {
    IAppState,
    ILicense,
    NotificationTypes,
    Statuses,
    TCommonState,
    THotkey,
    TModal,
    TModalType,
    TNotification,
    TNotificationData,
    TNotificationDetailMessage,
    TPlatformType,
    TState,
} from '@repeat/models';
import { LicenseService, TUploadedLicenseFile } from '@repeat/services';
import { TranslationKey } from '@repeat/translations';

import { checkUserAndDomain, loadUser } from './userSlice';

const { DISTRIB } = environment;

export const initialState: IAppState = {
    isInitialized: false,
    isLoading: false,
    notifications: [],
    modals: [],
    hotkeyPressed: {},
    meta: { platform: null },
    license: {
        activeLicense: null,
        newLicense: null,
        activationCode: null,
        getActiveKey: {
            status: Statuses.IDLE,
            error: null,
        },
        getNewKey: {
            status: Statuses.IDLE,
            error: null,
        },
        getActivationCode: {
            status: Statuses.IDLE,
            error: null,
        },
        saveKey: {
            status: Statuses.IDLE,
            error: null,
        },
        uploadLicenseFile: {
            status: Statuses.IDLE,
            error: null,
            fileSizeBytes: 0,
            uploadedBytes: 0,
        },
        activateLicense: {
            status: Statuses.IDLE,
            error: null,
        },
    },
};

export const initialLicense: ILicense = {
    licenseKey: null,
    activationPeriod: null,
    activationStatus: null,
    validationStatus: null,
    installedAt: null,
    createdAt: null,
    expireAt: null,
};

const reducers = {
    initializeRequest: (state: IAppState) => ({
        ...state,
        isInitialized: false,
        isLoading: true,
    }),
    initializeSuccess: (state: IAppState) => ({
        ...state,
        isInitialized: true,
        isLoading: false,
    }),
    showNotification: (state: IAppState, action: PayloadAction<{ notification: TNotification }>) => ({
        ...state,
        notifications: [...state.notifications, action.payload.notification],
    }),
    hideNotification: (state: IAppState, action: PayloadAction<{ index: number }>) => ({
        ...state,
        notifications: [
            ...state.notifications.slice(0, action.payload.index),
            ...state.notifications.slice(action.payload.index + 1),
        ],
    }),
    showModal: (state: IAppState, action: PayloadAction<{ modal: TModal }>) => ({
        ...state,
        modals: [...state.modals, action.payload.modal],
    }),
    hideModal: (state: IAppState, action: PayloadAction<{ type: TModalType }>) => {
        const index = state.modals.findIndex((modal: TModal) => modal.type === action.payload.type);

        if (index === -1) {
            return { ...state };
        }

        return {
            ...state,
            modals: [...state.modals.slice(0, index), ...state.modals.slice(index + 1)],
        };
    },
    setHotkeyPressed: (state: IAppState, action: PayloadAction<THotkey>) => ({
        ...state,
        hotkeyPressed: action.payload,
    }),
    setMeta: (state: IAppState, action: PayloadAction<{ platform: TPlatformType }>) => ({
        ...state,
        meta: { ...action.payload },
    }),
    // license
    uploadLicenseFileProgress: (state: IAppState, action: PayloadAction<{ bytes: number }>) => ({
        ...state,
        license: {
            ...state.license,
            uploadLicenseFile: {
                ...state.license.uploadLicenseFile,
                uploadedBytes: action.payload.bytes,
            },
        },
    }),
    uploadLicenseFileSuccess: (state: IAppState, action: PayloadAction<TUploadedLicenseFile>) => ({
        ...state,
        license: {
            ...state.license,
            uploadLicenseFile: {
                ...state.license.uploadLicenseFile,
                fileSizeBytes: action.payload.size,
            },
        },
    }),
};

const handleLicenseRequestError = (error: unknown) => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    if (error instanceof AxiosError) {
        if (error.code === 'ERR_NETWORK') {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.NETWORK_ERR_MSG,
                    },
                })
            );
            return;
        }

        if (error.response?.status === API_NOT_FOUND_CODE) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.LICENSE_KEY_NOT_FOUND,
                    },
                })
            );
            return;
        }

        if (error.response?.status === API_BAD_REQUEST_CODE) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.LICENSE_KEY_NOT_VALID,
                    },
                })
            );
            return;
        }
        if (error.response?.status === API_SERVICE_UNAVAILABLE_CODE) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.LICENSE_SERVICE_UNAVAILABLE,
                    },
                })
            );
            return;
        }
        if (error.response?.status === API_GATEWAY_TIMEOUT_CODE) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.LICENSE_GATEWAY_TIMEOUT,
                    },
                })
            );
            return;
        }

        if (error.response?.data?.errors) {
            const errorKey = TranslationKey.ERROR;
            const details: TNotificationDetailMessage[] = error.response.data.errors.map(
                (e: { code: number; message: string }) => ({
                    code: e.code,
                    message: e.message,
                })
            );
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                        details,
                    },
                })
            );
        } else {
            dispatch(
                ApplicationActions.showNotification({
                    notification: { type: NotificationTypes.ERROR, message: TranslationKey.ERROR_UNKNOWN },
                })
            );
        }
    } else {
        dispatch(
            ApplicationActions.showNotification({
                notification: { type: NotificationTypes.ERROR, message: TranslationKey.ERROR_UNKNOWN },
            })
        );
    }

    return;
};

const extraReducersAsyncThunks = {
    getActiveLicense: createAsyncThunk(
        'license/getActiveLicense',
        async (data: unknown, { dispatch, rejectWithValue }) => {
            try {
                const response = await LicenseService.getActiveKey();
                return response.data;
            } catch (error) {
                if (error instanceof AxiosError && error.response?.status === API_NOT_FOUND_CODE) {
                    return null;
                }

                dispatch(handleLicenseRequestError(error));

                return rejectWithValue(error);
            }
        }
    ),
    getNewLicense: createAsyncThunk('license/getNewLicense', async (data: unknown, { dispatch, rejectWithValue }) => {
        try {
            const response = await LicenseService.getNewKey();
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError && error.response?.status === API_NOT_FOUND_CODE) {
                return null;
            }

            dispatch(handleLicenseRequestError(error));

            return rejectWithValue(error);
        }
    }),
    saveLicenseKey: createAsyncThunk(
        'license/saveKey',
        async (data: { licenseKey: string }, { dispatch, rejectWithValue }) => {
            try {
                const response = await LicenseService.saveKey(data);
                return response.data;
            } catch (error) {
                dispatch(handleLicenseRequestError(error));

                return rejectWithValue(error);
            }
        }
    ),
    getLicenseActivationCode: createAsyncThunk('license/code', async (data: unknown, { dispatch, rejectWithValue }) => {
        try {
            const response = await LicenseService.getActivationCode();
            return response.data;
        } catch (error) {
            dispatch(handleLicenseRequestError(error));

            return rejectWithValue(error);
        }
    }),
    uploadLicenseFile: createAsyncThunk(
        'license/upload',
        async (data: { file: File }, { dispatch, rejectWithValue }) => {
            const onChunkUploaded = (uploadedBytes: number) => {
                dispatch(actions.uploadLicenseFileProgress({ bytes: uploadedBytes }));
            };

            const onUploaded = (uploadedFile: TUploadedLicenseFile) => {
                dispatch(actions.uploadLicenseFileSuccess(uploadedFile));
            };

            try {
                const response = await LicenseService.uploadLicenseFile({
                    file: data.file,
                    onChunkUploaded,
                    onUploaded,
                });
                return response;
            } catch (error) {
                dispatch(handleLicenseRequestError(error));

                return rejectWithValue(error);
            }
        }
    ),
    activateLicense: createAsyncThunk('license/activate', async (data: unknown, { dispatch, rejectWithValue }) => {
        try {
            const response = await LicenseService.activateLicense();
            return response.data;
        } catch (error) {
            dispatch(handleLicenseRequestError(error));

            return rejectWithValue(error);
        }
    }),
};

export const {
    saveLicenseKey,
    getLicenseActivationCode,
    uploadLicenseFile,
    activateLicense,
    getActiveLicense,
    getNewLicense,
} = extraReducersAsyncThunks;

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers,
    extraReducers: (builder: ActionReducerMapBuilder<IAppState>) =>
        builder
            .addCase(getActiveLicense.pending, (state) => {
                state.license.getActiveKey.status = Statuses.LOADING;
            })
            .addCase(getActiveLicense.rejected, (state, action) => {
                state.license.getActiveKey.status = Statuses.FAILED;
                state.license.getActiveKey.error = action.error?.message || null;
            })
            .addCase(getActiveLicense.fulfilled, (state, action) => {
                state.license.activeLicense = action?.payload
                    ? {
                          ...action.payload,
                          activationStatus: action.payload.activationStatus as TLicenseActivationStatus,
                          validationStatus: action.payload.validationStatus as TLicenseValidationStatus,
                      }
                    : null;
                state.license.getActiveKey.status = Statuses.SUCCEEDED;
            })
            .addCase(getNewLicense.pending, (state) => {
                state.license.getNewKey.status = Statuses.LOADING;
            })
            .addCase(getNewLicense.rejected, (state, action) => {
                state.license.getNewKey.status = Statuses.FAILED;
                state.license.getNewKey.error = action.error?.message || null;
            })
            .addCase(getNewLicense.fulfilled, (state, action) => {
                state.license.newLicense = action?.payload
                    ? {
                          ...action.payload,
                          activationStatus: action.payload.activationStatus as TLicenseActivationStatus,
                          validationStatus: action.payload.validationStatus as TLicenseValidationStatus,
                      }
                    : null;
                state.license.getNewKey.status = Statuses.SUCCEEDED;
            })
            .addCase(saveLicenseKey.pending, (state) => {
                state.license.saveKey.status = Statuses.LOADING;
            })
            .addCase(saveLicenseKey.rejected, (state, action) => {
                state.license.saveKey.status = Statuses.FAILED;
                state.license.saveKey.error = action.error?.message || null;
            })
            .addCase(saveLicenseKey.fulfilled, (state) => {
                state.license.saveKey.status = Statuses.SUCCEEDED;
            })
            .addCase(getLicenseActivationCode.pending, (state) => {
                state.license.getActivationCode.status = Statuses.LOADING;
            })
            .addCase(getLicenseActivationCode.rejected, (state, action) => {
                state.license.getActivationCode.status = Statuses.FAILED;
                state.license.getActivationCode.error = action.error?.message || null;
            })
            .addCase(getLicenseActivationCode.fulfilled, (state, action) => {
                state.license.getActivationCode.status = Statuses.SUCCEEDED;
                state.license.activationCode = action.payload.activationCode;
            })
            .addCase(uploadLicenseFile.pending, (state) => {
                state.license.uploadLicenseFile.status = Statuses.LOADING;
            })
            .addCase(uploadLicenseFile.rejected, (state, action) => {
                state.license.uploadLicenseFile.status = Statuses.FAILED;
                state.license.uploadLicenseFile.error = action.error?.message || null;
            })
            .addCase(uploadLicenseFile.fulfilled, (state) => {
                state.license.uploadLicenseFile.status = Statuses.SUCCEEDED;
            })
            .addCase(activateLicense.pending, (state) => {
                state.license.activateLicense.status = Statuses.LOADING;
            })
            .addCase(activateLicense.rejected, (state, action) => {
                state.license.activateLicense.status = Statuses.FAILED;
                state.license.activateLicense.error = action.error?.message || null;
            })
            .addCase(activateLicense.fulfilled, (state) => {
                state.license.activateLicense.status = Statuses.SUCCEEDED;
            }),
});

export const { actions, reducer } = appSlice;

export const initializeApp =
    (domain: string, navigate?: NavigateFunction) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: () => TCommonState) => {
        const state = getState() as TState;
        const { isInitialized, isLoading } = state.app;
        const token = getCookie('accessToken');

        if (isInitialized || isLoading) {
            return;
        }

        dispatch(actions.initializeRequest());

        await dispatch(getLicenseIfNecessary()).then(async () => {
            await dispatch(checkUserAndDomain(domain, navigate));
        });

        if (token) {
            // TODO make load user request if license is not required or exists
            await dispatch(loadUser());
        }

        await dispatch(setupLocale(navigate));
        await dispatch(setupTheme());

        dispatch(actions.setMeta({ platform: getPlatform() }));

        dispatch(actions.initializeSuccess());
    };

const getLicenseIfNecessary = () => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    try {
        if (!LICENSE_DISTRIBS.includes(DISTRIB)) {
            return;
        }

        await dispatch(getActiveLicense({}));
        await dispatch(getNewLicense({}));
    } catch (error: unknown) {
        console.error(error);
    }
};

// TODO refactor this approach
export const setupLocale =
    (navigate?: NavigateFunction) => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        const state = getState() as TState;
        const userLocale = state.appUser.currentUser?.settings?.locale;
        const detectedLocale = getAppLocale();
        if (userLocale && userLocale !== detectedLocale) {
            setLocale(userLocale);
            if (navigate) {
                navigate(0);
            }
        }
    };

export const setupTheme = () => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
    const state = getState() as TState;
    const userTheme = state.appUser.currentUser?.settings?.theme;
    if (userTheme) {
        setTheme(userTheme);
    }
};

export const installLicenseKey =
    (payload: { licenseKey: string }, navigate: NavigateFunction) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
        return dispatch(saveLicenseKey(payload))
            .unwrap()
            .then((payload) => {
                const installedLicense: ILicense | null = (payload as ILicense) || null;

                dispatch(getLicenseKeys()).then(() => {
                    if (
                        installedLicense.activationStatus === LicenseActivationStatuses.COMPLETED &&
                        installedLicense.validationStatus === LicenseValidationStatuses.VALID
                    ) {
                        navigate('/license');

                        dispatch(
                            ApplicationActions.showNotification({
                                notification: {
                                    type: NotificationTypes.SUCCESS,
                                    message: TranslationKey.LICENSE_ACTIVATION_SUCCESS,
                                },
                            })
                        );
                    } else {
                        navigate('/license/activation');
                    }
                });
                return;
            })
            .catch((_) => void 0);
    };

export const uploadAndActivateLicense =
    (payload: { file: File }, navigate: NavigateFunction) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
        return dispatch(uploadLicenseFile(payload))
            .then(() => {
                return dispatch(activateLicense({}))
                    .unwrap()
                    .then(() => {
                        return dispatch(getLicenseKeys()).then(() => {
                            navigate('/license');

                            dispatch(
                                ApplicationActions.showNotification({
                                    notification: {
                                        type: NotificationTypes.SUCCESS,
                                        message: TranslationKey.LICENSE_ACTIVATION_SUCCESS,
                                    },
                                })
                            );
                        });
                    })
                    .catch((_) => void 0);
            })
            .catch((e) => {
                throw e;
            });
    };

export const justActivateLicense =
    (navigate: NavigateFunction) => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
        return dispatch(activateLicense({}))
            .unwrap()
            .then(() => {
                dispatch(getLicenseKeys()).then(() => {
                    navigate('/license');
                });
                return;
            })
            .catch((_) => void 0);
    };

export const getLicenseKeys = () => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return Promise.all([dispatch(getActiveLicense({})), dispatch(getNewLicense({}))]);
};

export const checkLicenseExpiration = () => (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
    const state = getState() as TState;
    const activeLicense = state.app.license.activeLicense;

    const licenseDuration = getExpirationDuration(activeLicense?.expireAt);
    if (!licenseDuration) {
        return;
    }

    if (licenseDuration.days > 0 && licenseDuration.days <= LICENSE_WARNING_LEFT_DAYS) {
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.WARNING,
                    message: TranslationKey.LICENSE_EXPIRATION_SOON,
                    details: [
                        {
                            message: TranslationKey.LICENSE_EXPIRATION_LEFT_DAYS,
                            data: { ...licenseDuration } as TNotificationData,
                        },
                    ],
                },
            })
        );
    } else if (licenseDuration.days === 0) {
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.WARNING,
                    message: TranslationKey.LICENSE_EXPIRATION_SOON,
                    details: [
                        {
                            message: TranslationKey.LICENSE_EXPIRATION_TODAY,
                            data: { ...licenseDuration } as TNotificationData,
                        },
                    ],
                },
            })
        );
    }
};
