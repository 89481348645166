import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { ApplicationActions } from '@repeat/common-slices';
import { chartTitle } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { EChartItemType, IChartItem, IChartItemType, ModalTypes, TGroupCreateWarningModal } from '@repeat/models';
import { addGroup, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Alert, AlertType, Button, Modal } from '@repeat/ui-kit';

import { groupCreateWarningModalMessages as messages } from './translation';

interface IChartItemWithIndex {
    index: number;
    chart: IChartItem;
}

export const GroupCreateWarningModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const modal = useAppSelector((state) => state.app.modals[0]) as TGroupCreateWarningModal;
    const charts = useAppSelector(workspaceSelectors.charts);

    const metersBlocks = modal.data.metersElements;

    const metersElementsNames = metersBlocks.map((el) => `${el.data.name} [${el.data.index}]`);

    let metersModelNames: string[] = [];
    metersBlocks.forEach((block) => {
        metersModelNames = [
            ...metersModelNames,
            ...block.data.elemParams.map((parameter) => `${block.data.id}_${parameter.name}`),
        ];
    });

    let chartsWithMeters: IChartItemWithIndex[] = [];
    Object.values(charts).forEach((chart: IChartItem, index) => {
        const intersection = chart.modelNames.filter((x) => metersModelNames.includes(x));
        if (intersection.length > 0) {
            chartsWithMeters = [
                ...chartsWithMeters,
                {
                    index: index + 1,
                    chart,
                } as IChartItemWithIndex,
            ];
        }
    });

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.GROUP_CREATE_WARNING }));
    };

    const handleAdd = () => {
        dispatch(addGroup(modal.data));
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.GROUP_CREATE_WARNING }));
    };

    const [isValidForm, setIsValidForm] = useState(true);

    const chartTypeName = (type: IChartItemType | null) => {
        if (type === EChartItemType.YFromX) {
            return intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_TYPE_Y_X]);
        }
        return intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_TYPE_Y_T]);
    };

    const defaultChartTitle = useCallback((chart: IChartItem) => {
        return `${intl.formatMessage(messages[TranslationKey.CHART])} ${chartTypeName(
            chart.type
        )}. ${intl.formatMessage(messages[TranslationKey.PARAMETERS])}: ${chart.YParameters.length}`;
    }, []);

    const FooterButtons = () => (
        <>
            <Button variant='secondary' onClick={handleClose}>
                {intl.formatMessage(messages[TranslationKey.CANCEL])}
            </Button>
            <Button onClick={handleAdd} disabled={!isValidForm}>
                {intl.formatMessage(messages[TranslationKey.GROUP_CREATING_WARNING_ACCEPT])}
            </Button>
        </>
    );

    return (
        <Modal
            id={ModalTypes.USER_BLOCK_SAVE}
            title={intl.formatMessage(messages[TranslationKey.GROUP_CREATING_WARNING_TITLE])}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='medium'
            footer={<FooterButtons />}
        >
            <Alert type={AlertType.WARNING}>
                <div>
                    <p>{intl.formatMessage(messages[TranslationKey.GROUP_CREATING_WARNING_BLOCKS])}</p>
                    <br />
                    <ul>
                        {metersElementsNames.map((el) => (
                            <li key={el}>{el}</li>
                        ))}
                    </ul>
                    {chartsWithMeters.length > 0 && (
                        <>
                            <br />
                            <p>{intl.formatMessage(messages[TranslationKey.GROUP_CREATING_WARNING_CHARTS])}</p>
                            <br />
                            <ul>
                                {chartsWithMeters.map((chartWithIndex) => (
                                    <li key={chartWithIndex.chart.uuid}>
                                        {`${chartWithIndex.index}. ${chartTitle(
                                            chartWithIndex.chart?.layout?.title,
                                            defaultChartTitle(chartWithIndex.chart)
                                        )}`}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </Alert>
        </Modal>
    );
};
