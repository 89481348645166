import { TAccountDeleteFormData, TAccountEdiFormtData } from './account';
import { TApiKey } from './apiKeys';
import { ILibraryItem } from './libraryItem';
import { Project } from './project';
import { TSchemaGroup, TSchemaNode } from './schema/index';
import { TTechnicalSupportTicket } from './technical-support';
import { TVersionHistoryData } from './versions-page';

export enum ModalTypes {
    PROJECT_CREATE = 'project-create',
    PROJECT_IMPORT = 'project-import',
    PROJECT_DELETE = 'project-delete',
    PROJECTS_DELETE = 'projects-delete',
    PROJECT_EDIT = 'project-edit',
    PROJECT_SAVE_NEW = 'project-save-new',
    ELEMENT_CONFIGURATION = 'element-configuration',
    ELEMENT_INITIALIZATION = 'element-initialization',
    RELEASE_INFO = 'release-info',
    TECHNICAL_SUPPORT = 'technical-support',
    ACCOUNT_ADD = 'account-add',
    ACCOUNT_EDIT = 'account-edit',
    ACCOUNT_DELETE = 'account-delete',
    ERROR = 'error',
    PROJECT_BLOCK_INITIALIZATION = 'project-block-initialization',
    PROJECT_CONFIRM_SAVING = 'project-confirm-saving',
    LIBRARYITEM_IMPORT = 'libraryItem-import',
    NEWBLOCK_INFO = 'new-block-info',
    NEWBLOCK_EDIT = 'new-block-edit-form',
    API_KEY_CREATE = 'api-key-create',
    API_KEY_DELETE = 'api-key-delete',
    PULSEQ_INITIALIZATION = 'pulseq-initialization',
    USER_BLOCK_SAVE = 'user-block-save',
    USER_BLOCK_EDIT = 'user-block-edit',
    USER_BLOCK_CONFIRM_DELETE = 'user-block-confirm-delete',
    MODAL_SESSION_CONNECT = 'modal-session-connect',
    OPEN_BUSY_PROJECT_MODAL = 'open-busy-project-modal',
    GROUP_CREATE_WARNING = 'group-create-warning-modal',
}

export type TModalType =
    | ModalTypes.PROJECT_CREATE
    | ModalTypes.PROJECT_IMPORT
    | ModalTypes.PROJECT_EDIT
    | ModalTypes.PROJECT_DELETE
    | ModalTypes.PROJECTS_DELETE
    | ModalTypes.ELEMENT_CONFIGURATION
    | ModalTypes.ELEMENT_INITIALIZATION
    | ModalTypes.RELEASE_INFO
    | ModalTypes.TECHNICAL_SUPPORT
    | ModalTypes.MODAL_SESSION_CONNECT
    | ModalTypes.OPEN_BUSY_PROJECT_MODAL
    | ModalTypes.PROJECT_SAVE_NEW
    | ModalTypes.PROJECT_BLOCK_INITIALIZATION
    | ModalTypes.PROJECT_CONFIRM_SAVING
    | ModalTypes.LIBRARYITEM_IMPORT
    | ModalTypes.NEWBLOCK_INFO
    | ModalTypes.NEWBLOCK_EDIT
    | ModalTypes.API_KEY_CREATE
    | ModalTypes.API_KEY_DELETE
    | ModalTypes.PULSEQ_INITIALIZATION
    | ModalTypes.ACCOUNT_ADD
    | ModalTypes.ACCOUNT_EDIT
    | ModalTypes.ACCOUNT_DELETE
    | ModalTypes.ERROR
    | ModalTypes.USER_BLOCK_SAVE
    | ModalTypes.USER_BLOCK_EDIT
    | ModalTypes.USER_BLOCK_CONFIRM_DELETE
    | ModalTypes.GROUP_CREATE_WARNING;

export type TDefaultModal = {
    type: TModalType;
    data?: undefined;
};

export type TProjectEditModal = {
    type: TModalType;
    data: {
        project: Project;
    };
};

export type TProjectSaveNewModal = {
    type: TModalType;
    data: {
        project: Pick<Project, 'projectName' | 'projectId'>;
    };
};

export type TProjectBlockInitialization = {
    type: TModalType;
    data: {
        node: TSchemaNode;
    };
};

export type TBlockFileUpload = {
    type: TModalType;
    data?: undefined;
};

export type TProjectConfirmSaving = {
    type: TModalType;
    data?: undefined;
};

export type TProjectDeleteModal = {
    type: TModalType;
    data: {
        project: Project;
    };
};

export type TProjectsDeleteModal = {
    type: TModalType;
    data: {
        projects: Project[];
    };
};

export type TApiKeyDeleteModal = {
    type: TModalType;
    data: {
        apiKey: TApiKey;
    };
};

export type TElementConfigurationModal = {
    type: TModalType;
    data?: undefined;
};

export type TElementInitializationModal = {
    type: TModalType;
    data?: undefined;
};

export type TReleaseInfoModal = {
    type: TModalType;
    data: {
        content: TVersionHistoryData | null;
        actual_release_version: string;
    };
};

export type TTechnicalSupportModal = {
    type: TModalType;
    data: Pick<TTechnicalSupportTicket, 'userName' | 'email'>;
};

export enum ReleaseModalCloseType {
    CLOSE = 'close',
    DONT_SHOW_RELEASES = 'dontShowReleases',
}

export type TAccountEditModal = {
    type: TModalType;
    data: TAccountEdiFormtData;
};

export type TAccountDeleteModal = {
    type: TModalType;
    data: TAccountDeleteFormData;
};

export type TErrorModal = {
    type: TModalType;
    data: {
        errorKey: string;
    };
};

export type TNewBlockInfoModal = {
    type: TModalType;
    data: {
        block: ILibraryItem;
    };
};

export type TDevBlockEditModal = {
    type: TModalType;
    data: {
        block: ILibraryItem;
    };
};
export type TUserBlockSaveModal = {
    type: TModalType;
    data: {
        groups: TSchemaGroup[];
        element: TSchemaNode;
    };
};
export type TUserBlockEditModal = {
    type: TModalType;
    data: {
        blockId: string | null;
        fileManagerId?: number;
    };
};
export type TUserBlockConfirmDeleteModal = {
    type: TModalType;
    data: {
        name: string;
        blockId: string;
    };
};

export type TSessionConnectModal = {
    type: TModalType;
    data?: {
        message: string;
    };
};

export type TOpenBusyProjectModal = {
    type: TModalType;
    data: {
        code: number;
        modules: string[];
    };
};

export type TGroupCreateWarningModal = {
    type: TModalType;
    data: { elementId: string; node: TSchemaNode; metersElements: TSchemaNode[] };
};

export type TModal =
    | TDefaultModal
    | TProjectEditModal
    | TProjectDeleteModal
    | TProjectsDeleteModal
    | TElementConfigurationModal
    | TElementInitializationModal
    | TReleaseInfoModal
    | TTechnicalSupportModal
    | TSessionConnectModal
    | TOpenBusyProjectModal
    | TProjectSaveNewModal
    | TNewBlockInfoModal
    | TDevBlockEditModal
    | TApiKeyDeleteModal
    | TBlockFileUpload
    | TAccountEditModal
    | TAccountDeleteModal
    | TErrorModal
    | TUserBlockSaveModal
    | TUserBlockEditModal
    | TUserBlockConfirmDeleteModal
    | TProjectBlockInitialization
    | TGroupCreateWarningModal;
